import { useEffect } from 'react';
import { mockHeadData } from 'utils/metaDataUtils/headData';
import { HeaderNav } from 'interfaces/navigation/Header';
import { PianoPageConfig } from 'services/Piano/entities/PianoPageConfig';
import { setMockPianoConfig } from 'services/Piano';
import Error404 from 'components/Error404';
import { IterablePageData } from 'services/Iterable/entities';
import setIterablePageData from 'services/Iterable/setIterablePageData';
import setPageGtmConfig from 'services/Gtm/setPageGtmConfig';
import { PageViewDataLayerObject } from 'services/Gtm/entities';
import mockPageData from 'src/main/lib/mockData/page-data';
import { ArcSpanPageConfig } from 'services/ArcSpan/entities';
import { setarcSpanPageConfig } from 'services/ArcSpan';
import getFooter from 'api/Footer/getFooter';
import getMenu from 'api/Header/getMenu';
import { getGtmPageStatusEvent, pushToDataLayer } from 'services/Gtm/functions';
import handleStaticPropsError from 'utils/handleStaticPropsError';

const Custom404 = () => {
  useEffect(() => {
    pushToDataLayer(getGtmPageStatusEvent(true, 404));
  }, []);

  return (
    <Error404
      heading='Oops! Page not found'
      link='homepage'
      msg='Our apologies. It may have expired or there could be a typo.
      Search for what you are looking for or find what you need on our'
    />
  );
};

export async function getStaticProps() {
  try {
    const menu: HeaderNav = await getMenu('LEFT_MENU_COM', 'RIGHT_MENU_COM', 'TOPIC_NAVIGATION_COM', 'own', null).catch(
      () => ({
        domainLogo: null,
        leftMenu: [],
        rightMenu: [],
        subDomain: 'own',
        topicNavigation: [],
      }),
    );

    const footer = await getFooter().catch(() => ({
      footerDisclaimer: '',
      footerNav: [],
      socialList: [],
    }));

    const dataLayer: PageViewDataLayerObject = setPageGtmConfig(mockPageData);
    const iterablePageViewData: IterablePageData = setIterablePageData(mockPageData);
    const arcSpanPageConfig: ArcSpanPageConfig = setarcSpanPageConfig(mockPageData);
    const pianoPageConfig: PianoPageConfig = setMockPianoConfig();

    return {
      props: {
        arcSpanPageConfig,
        dataLayer,
        footer,
        headData: mockHeadData,
        headerNav: menu,
        iterablePageViewData,
        pianoPageConfig,
      },
    };
  } catch (e) {
    return handleStaticPropsError(e as Error);
  }
}

export default Custom404;
