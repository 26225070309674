import Link from 'components/Globals/Base/Link';
import SearchBar from 'components/SearchBar';
import { useRouter } from 'next/router';
import { BackLink, Msg, MsgLink, Title, Wrapper } from './Error404Styles';

export interface Error404Props {
  heading: string;
  link: string;
  msg: string;
}

export const Error404 = ({ heading, link, msg }: Error404Props) => {
  const router = useRouter();

  return (
    <>
      <Wrapper>
        <BackLink onClick={() => router.back()}>&#x3c; Back</BackLink>
        <Title
          as='h1'
          $fontFamily='saol'
          $size={{ default: 'level2', mdMin: 'large' }}
        >
          {heading}
        </Title>
        <Msg>
          {msg}
          <MsgLink>
            <Link href='/'>{link}</Link>
          </MsgLink>
        </Msg>
      </Wrapper>
      <SearchBar />
    </>
  );
};

export default Error404;
