import Input from 'components/Globals/Base/Input';
import Button from 'components/Globals/Base/Button';
import { useState } from 'react';
import { changeRoute } from 'components/Globals/Base/Link';
import SearchWrapper from './SearchBarStyles';

const SearchBar = () => {
  const [value, setValue] = useState('');
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // TODO: Update this when reverting back to next/link
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    changeRoute(`https://fortune.com/advanced-search/?query=${value}`);
  };
  return (
    <SearchWrapper>
      <form
        method='get'
        onSubmit={handleSubmit}
        aria-label='search'
      >
        <Input
          placeholder='What are you looking for?'
          value={value}
          onChange={setValue}
        />
        <Button
          type='submit'
          variant='primary'
        >
          SEARCH
        </Button>
      </form>
    </SearchWrapper>
  );
};

export default SearchBar;
