import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';
import Heading from 'styles/typography/Heading';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${font.graphikCond};
  justify-content: center;
  margin: 30px auto;
  position: relative;
  width: 65%;
`;

export const BackLink = styled.button<{ theme: Theme }>`
  all: unset;
  color: ${getColor('textPrimary')};
  font-family: ${font.graphikCond};
  font-size: 16px;
  font-weight: 700;
  left: -48px;
  position: relative;
  text-decoration: none;
  top: 0;
  cursor: pointer;

  @media (${breakpoint.lgMin}) {
    font-size: 20px;
  }
`;

export const Title = styled(Heading)`
  margin: 1em 0;
  text-align: center;
`;

export const Msg = styled.p<{ theme: Theme }>`
  color: ${getColor('textSecondary')};
  font-family: ${font.graphikCond};
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
  padding: 1vw 5vw;

  @media (${breakpoint.mdMin}) {
    font-size: 24px;
  }
`;

export const MsgLink = styled.span<{ theme: Theme }>`
  a {
    border: none;
    border-radius: 3px;
    color: ${getColor('textLink')};
    display: inline;
    font-family: ${font.graphikCond};
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.5px;
    margin-left: 4px;
    text-align: center;
    text-decoration: none;

    @media (${breakpoint.mdMin}) {
      font-size: 24px;
    }
  
    &::after {
      color: ${getColor('textSecondary')};
      content: ".";
  
      @media (${breakpoint.mdMin}) {
        font-size: 24px;
      }
    }
  }
`;
