import styled, { css } from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getBodyStyle, getColor, Theme } from 'styles/theme';
import orderBreakpoints from 'utils/miscUtils/orderBreakpoints';
import { InputSizes } from './types';

export const inputSizes: InputSizes = {
  large: css`
    height: 56px;
    padding: 16px;
  `,
  medium: css`
    height: 48px;
  `,
  small: css`
    height: 40px;
    padding: 8px 16px;
  `,
};

const handleVariant = (variant: string) => {
  if (variant === 'discrete') {
    return css`
      border-width: 0 0 1px 0;
      padding: 8px;
    `;
  }
  if (variant === 'underlined') {
    return css<{ theme: Theme }>`
      border: 0;
      border-bottom: 1px solid ${getColor('borderTertiary')};
      padding: 16px 16px 16px 48px;
      width: 100%;

      &:hover {
        border-bottom: 1px solid ${getColor('borderAccentPrimary')};
      }
      
      &:focus, &:active {
        border-bottom: 1px solid ${getColor('borderAccentPrimary')};
        box-shadow: none;
      }
    `;
  }
  return ``;
};

export const InputWrapper = styled.div<{ theme: Theme; $variant?: 'default' | 'discrete' | 'underlined' }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  ${({ $variant }) =>
    $variant === 'underlined' &&
    `
    width: 100%;
  `}

  &.error {
    input {
      border: 1px solid ${getColor('semanticsError')};
    }

    span,
    label {
      color: ${getColor('textAccentSecondary')};
    }
  }

  &.disabled {
    input {
      border: 1px solid ${getColor('neutralLightGray3')};
    }

    span,
    label {
      color: ${getColor('textSecondary')};
    }
  }

  ${({ $variant }) =>
    $variant === 'underlined' &&
    css`
      &::before {
        content: url('/icons/ButtonIcons/Search.svg');
        position: absolute;
        width: 16px;
        top: 20px;
        left: 16px;
        z-index: 1;
        filter: contrast(0.5);
      }
    `}
`;

export const Label = styled.label<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'default' }, 'regular')}
  line-height: 24px;
  letter-spacing: 0.5px;
  color: ${getColor('textPrimary')};

  span {
    color: ${getColor('textAccentSecondary')};

    &.disclaimer {
      color: ${getColor('textPrimary')}
    }
  }
`;

export const Input = styled.input<{
  $size: { default: keyof InputSizes; [key: string]: keyof InputSizes };
  $variant: 'default' | 'discrete' | 'underlined';
  theme: Theme;
}>`
  ${getBodyStyle('graphikCond', { default: 'default' })}
  line-height: 24px;
  border: 1px solid ${({ $variant }) => {
    switch ($variant) {
      case 'discrete':
        return getColor('borderPrimary');
      case 'underlined':
        return 'transparent';
      default:
        return getColor('borderTertiary');
    }
  }};

  ${({ $variant }) => ($variant === 'underlined' || $variant === 'discrete') && `width: 100%;`};

  ::placeholder,
  ::-moz-placeholder {
    color: ${getColor('textSecondary')};
  }

  &:hover {
    border: 1px solid ${getColor('borderAccentPrimary')};
    ${({ $variant }) => handleVariant($variant)};
  }

  &:focus-visible,
  &:focus,
  &:active {
    box-shadow: 0 0 5px 0 ${getColor('textLink')};
    outline: none;
  }

  &:valid {
    color: ${getColor('textPrimary')};
  }

  &:disabled {
    border: 1px solid ${getColor('neutralLightGray3')};
  }

  &.error {
    border: 1px solid ${getColor('semanticsError')};

    span,
    label {
      color: ${getColor('textAccentSecondary')};
    }
  }

  ${({ $size }) => {
    const { default: defaultSize, ...customSizes } = $size || { default: 'large' };
    const breakpoints = orderBreakpoints(customSizes) as { [key: string]: keyof InputSizes };

    return css`
  ${inputSizes[defaultSize]};
  ${Object.keys(breakpoints).map(
    (bkp: string) => css`
      @media (${breakpoint[bkp]}) {
        ${inputSizes[breakpoints[bkp]]};
      }
    `,
  )}
  `;
  }}

  ${({ $variant }) => handleVariant($variant)};
`;

export const Text = styled.span<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'xsmall' }, 'regular')}
  letter-spacing: 0.5;
  color: ${getColor('textPrimary')};
  line-height: 18px;

  &.disabled {
    color: ${getColor('textSecondary')};
  }

  &.error {
    color: ${getColor('textAccentSecondary')};
  }
`;
