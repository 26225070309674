import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getBodyStyle, getColor } from 'styles/theme';

const SearchWrapper = styled.div<{ theme: Theme }>`
  margin-bottom: 32px;
  padding: 0 16px 24px;

  form {
    display: flex;
    justify-content: center;

    input {
      ${getBodyStyle('graphikCompact', { default: 'large' })};
      height: 50px;
      width: calc(65vw - 50px);
      border-radius: 2px;
      border-right: 0;

      ::placeholder {
        color: ${getColor('textPrimary')};
      }

      :focus-visible {
        border: 1px solid;
        outline: -webkit-focus-ring-color auto 1px;
      }

      :hover {
        outline: 0;
      }

      @media (${breakpoint.mdMin}) {
        left: 33vw;
        width: calc(50vw - 100px);
      }
    }

    button {
      height: 50px;
      padding: 0 32px;

      :focus {
        box-shadow: none;
        outline: 0;
      }

      @media (${breakpoint.mdMin}) {
        height: 50px;
      }
    }
  }

`;

export default SearchWrapper;
